<template>
<div id='productName'>
  <div id='kraftLogo'></div>
</div>

<div id='d3Canvas'></div>
<div id='DetailsContent'></div>
<div id='secureContent'>
  <div id='closeSecure' />
  <div class='secureBlock'>
    <div class='title'>Enter the secure key</div>
    <input id='secureWord' type="password" />
    <div id='checkSecureKey'>unlock</div>
  </div>
  <div />
</div>
<!--<div id='titleBlock'>
  <div class='pageSubtitle'>{{subtitle}}</div>
</div>-->
<div id='leftControls'>
  <div id='modeSwitcher'>
    <div id='ModeCombo' class='combo'>
      <div class='title'></div>
      <div class='arrow'></div>
    </div>
    <div class='modelist'>
      <div class='item' data-modeid=0><span>old version</span></div>
      <div class='item' data-modeid=1><span>basic</span></div>
      <div class='item' data-modeid=2><span>internal display</span></div>
      <div class='item' data-modeid=3><span>on-door display</span></div>
    </div>
  </div>
  <div id='moreDetails'></div>
</div>
<div id='leftBottomControls'>
  <div id='secureWidget'></div>
</div>

<div id='ControlsCanvas'>
  <div class='btn first'></div>
  <div class='btn second'></div>
  <div class='btn third'></div>
  <div class='btn fourth'></div>
  <div class='btn fifth'></div>
  <div class='btn sixth'></div>
</div>
<div id='PreparingMsg' class='PreparingMsg'>preparing...</div>
</template>

<script>
import {
  CameraStartPosition,
  DoorPosition,
  CapPosition,
  CapPillarPosition,
  CapPillarPistonsPosition,
  DoorHandlePosition,
  BoxDoorPosition
} from '../functions/ObjectPositions.js'
import * as THREE from 'three'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js'
import {
  gsap
} from 'gsap'

var sha256 = require('js-sha256')

const modeNames = ['SmartKraft +',
'SmartKraft 2 <span style="font-family: ITCAvantGarde-Bk;">option 1</span>',
'SmartKraft 2 <span style="font-family: ITCAvantGarde-Bk;">option 2</span>',
'SmartKraft 2 <span style="font-family: ITCAvantGarde-Bk;">option 3</span>']

export default {
  name: 'd3Canvas',
  data() {
    return {
      subtitle: '— is the next generation of KraftPowercon’s industry leading high frequency ESP power supply technology.',
      modeName: 'basic',
      orbitControl: new OrbitControls(this.d3.camera, this.d3.renderer.domElement),
      doorPos: {
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0
      },
      doorHandlePos: {
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0
      },
      capPos: {
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0
      },
      capPillarPos: {
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0
      },
      doorBoxPos: {
        x: 0,
        y: 0,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0
      },
      glassOpacity: 1,
      fansBlockOpacity: 1,
      powerBlockOpacity: 1
    }
  },
  watch: {
    modeName(/*newval, oldval*/) {
      let modeId = this.$store.getters['general/mode']()
      switch(modeId) {
        case 0:
          this.subtitle = '- The old version mode has a previous design which are prefers retro lovers'
          break
        case 1:
          this.subtitle = '- The basic mode is the simplest design which there are no any displays but still powerful'
          break
        case 2:
          this.subtitle = '- The internal mode has inside display. It may be useful when data uses time-to-time'
          break
        case 3:
          this.subtitle = '- The external mode has on-door display. This mode are prefers curious people'
          break
      }
    }
  },
  props: {
    msg: String
  },
 beforeMount() {
   this.$store.watch(this.$store.getters['general/is3DLoaded'], () => {
     this.initScene()
     let showControlsAnim = gsap.to("#titleBlock, #ControlsCanvas, #productName, #smartLogo, #leftControls, #leftBottomControls", {
       opacity: 1,
       duration: 0.7,
       ease: "power4.in" //,
       //onComplete: this.onControlsShown
     })
     showControlsAnim.play()
   })

 },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
    let btn0 = document.getElementsByClassName('btn first')[0]
    let btn1 = document.getElementsByClassName('btn second')[0]
    let btn2 = document.getElementsByClassName('btn third')[0]
    let btn3 = document.getElementsByClassName('btn fourth')[0]
    let btn4 = document.getElementsByClassName('btn fifth')[0]
    let btn5 = document.getElementsByClassName('btn sixth')[0]
    let ModeCombo = document.getElementById('ModeCombo')
    let ModeItems = document.querySelectorAll("#modeSwitcher .modelist .item")
    let moreDetails = document.getElementById('moreDetails')
    let secureWidget = document.getElementById('secureWidget')
    let closeSecure = document.getElementById('closeSecure')
    let checkSecureKey = document.getElementById('checkSecureKey')
    let secureWord = document.getElementById('secureWord')

    ModeCombo.addEventListener('click', this.onModeComboClick)
    moreDetails.addEventListener('click', this.onMoreInfoClick)
    secureWidget.addEventListener('click', this.onSecureClick)
    closeSecure.addEventListener('click', this.onCloseSecureClick)
    checkSecureKey.addEventListener('click', this.oncheckSecureKeyClick)
    secureWord.addEventListener('keydown', this.oncheckSecureKeyClick)
    btn0.addEventListener('click', this.onFirstBtnClick)
    btn1.addEventListener('click', this.onSecondBtnClick)
    btn2.addEventListener('click', this.onThirdBtnClick)
    btn3.addEventListener('click', this.onFourthBtnClick)
    btn4.addEventListener('click', this.onFifthBtnClick)
    btn5.addEventListener('click', this.onSixthBtnClick)

    this.modeName = modeNames[this.$store.getters['general/mode']()]
    for(var i=0; i<ModeItems.length; i++) {
      ModeItems[i].children[0].innerHTML = modeNames[i]
      ModeItems[i].addEventListener('click', this.onModeComboItemClick)
    }
    var comboTitle = document.getElementById('ModeCombo').children[0]
    comboTitle.innerHTML = modeNames[this.$store.getters['general/mode']()]

    this.$store.watch(this.$store.getters['general/isSecureMode'], (issecure) => {
      let secureWidget = document.getElementById('secureWidget')
      if (!issecure) {
        secureWidget.classList.add("off")
      } else {
        secureWidget.classList.remove("off")
      }
    })

    this.$store.watch(this.$store.getters['general/mode'], (modeId) => {
      let opacityOldDisplay = 1
      let opacityInternalDisplay = 1
      let opacityOnDoorDisplay = 1
      let duration = 0.1
      this.modeName = modeNames[modeId]
      var comboTitle = document.getElementById('ModeCombo').children[0]
      comboTitle.innerHTML = modeNames[modeId]
      switch (modeId) {
        case 0:
          opacityOldDisplay = 1
          opacityInternalDisplay = 0
          opacityOnDoorDisplay = 0
          break
        case 1:
          opacityOldDisplay = 0
          opacityInternalDisplay = 0
          opacityOnDoorDisplay = 0
          break
        case 2:
          opacityOldDisplay = 0
          opacityInternalDisplay = 1
          opacityOnDoorDisplay = 0
          break
        case 3:
          opacityOldDisplay = 0
          opacityInternalDisplay = 0
          opacityOnDoorDisplay = 1
          break
        default:
          opacityOldDisplay = 0
          opacityInternalDisplay = 0
          opacityOnDoorDisplay = 1
      }

      gsap.to(this.d3.old_controller.scale, {
        x: opacityOldDisplay,
        y: opacityOldDisplay,
        z: opacityOldDisplay,
        duration: duration,
        ease: "power4.out"
      })

      gsap.to(this.d3.new_controller.scale, {
        x: 1-opacityOldDisplay,
        y: 1-opacityOldDisplay,
        z: 1-opacityOldDisplay,
        duration: duration,
        ease: "power4.out"
      })


      gsap.to(this.d3.doordisplay.scale, {
        x: opacityOnDoorDisplay,
        y: opacityOnDoorDisplay,
        z: opacityOnDoorDisplay,
        duration: duration,
        ease: "power4.out"
      })

      gsap.to(this.d3.internaldisplay.scale, {
        x: opacityInternalDisplay,
        y: opacityInternalDisplay,
        z: opacityInternalDisplay,
        duration: duration,
        ease: "power4.out"
      })
    })

    this.$store.watch(this.$store.getters['general/isDoorOpened'], (isopened) => {
      let btn = document.getElementsByClassName('btn first')[0]
      if (isopened) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimDoor(isopened))
      masterTL.play()
    })

    this.$store.watch(this.$store.getters['general/isHoodOpened'], (isopened) => {
      let btn = document.getElementsByClassName('btn second')[0]
      if (isopened) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimHood(isopened))
      masterTL.play()
    })

    this.$store.watch(this.$store.getters['general/isBoxDoorOpened'], (isopened) => {
      let btn = document.getElementsByClassName('btn third')[0]
      if (isopened) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimBoxDoor(isopened))
      masterTL.play()
    })

    this.$store.watch(this.$store.getters['general/isGlassRemoved'], (isremoved) => {
      let btn = document.getElementsByClassName('btn fourth')[0]
      if (isremoved) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      if (isremoved && !this.$store.getters['general/isDoorOpened']()) {
        this.$store.dispatch("general/changeDoorState", true)
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimGlass(isremoved))
      masterTL.play()
    })

    this.$store.watch(this.$store.getters['general/isFansBlockRemoved'], (isremoved) => {
      let btn = document.getElementsByClassName('btn fifth')[0]
      if (isremoved) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      if (isremoved && !this.$store.getters['general/isGlassRemoved']()) {
        this.$store.dispatch("general/changeGlassState", true)
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimFansBlock(isremoved))
      masterTL.play()
    })

    this.$store.watch(this.$store.getters['general/isPowerBlockRemoved'], (isremoved) => {
      let btn = document.getElementsByClassName('btn sixth')[0]
      if (isremoved) {
        btn.classList.add("on")
      } else {
        btn.classList.remove("on")
      }
      if (isremoved && !this.$store.getters['general/isFansBlockRemoved']()) {
        this.$store.dispatch("general/changeFansBlockState", true)
      }
      let masterTL = gsap.timeline()
      masterTL.add(this.AnimPowerBlock(isremoved))
      masterTL.play()
    })
  },
  methods: {
    render() {
      this.orbitControl.update()
      this.d3.renderer.render(this.d3.scene, this.d3.camera)
    },
    onWindowResize() {
      const aspect = window.innerWidth / window.innerHeight
      const frustumHeight = this.d3.camera.top - this.d3.camera.bottom
      this.d3.camera.left = -frustumHeight * aspect / 2
      this.d3.camera.right = frustumHeight * aspect / 2
      this.d3.camera.aspect = aspect
      this.d3.camera.updateProjectionMatrix()
      this.d3.renderer.setPixelRatio(window.devicePixelRatio)
      this.d3.renderer.setSize(window.innerWidth, window.innerHeight)
      this.render()
    },
    initScene() {
      THREE.Cache.enabled = true
      this.d3.renderer.setSize(window.innerWidth, window.innerHeight)
      this.d3.renderer.gammaFactor = 2.2
      this.d3.renderer.setPixelRatio(window.devicePixelRatio)
      this.d3.renderer.setAnimationLoop(this.render)
      document.getElementById('d3Canvas').appendChild(this.d3.renderer.domElement)

      const pmremGenerator = new THREE.PMREMGenerator(this.d3.renderer)
      pmremGenerator.compileEquirectangularShader()

      //this.d3.scene.background = new THREE.Color( 0xe2e2e2 )
      this.d3.scene.add(this.d3.gltf_scene)
      this.d3.camera.position.set(0.0, 6.0, 12.0)
      this.d3.camera.lookAt(0, 3.5, -0.2)

      const light = new THREE.AmbientLight(0x606060, 0.4) // soft white light
      this.d3.scene.add(light);

      let pointLight = new THREE.PointLight(0xffffff, 0.8, 70, 2)
      pointLight.position.set(0, 5, 75)
      this.d3.scene.add(pointLight)

      pointLight = new THREE.PointLight(0xffffff, 0.8, 70, 2)
      pointLight.position.set(0, 5, -75)
      this.d3.scene.add(pointLight)

      const intensivity = 0.2
      let directLight = new THREE.DirectionalLight( 0xFFFFFF, intensivity )
      directLight.position.x = -40
      directLight.position.y = 40
      directLight.position.z = 40
      //let directLightHelper = new THREE.DirectionalLightHelper( directLight, 5 )
      this.d3.scene.add( directLight )

      directLight = new THREE.DirectionalLight( 0xFFFFFF, intensivity )
      directLight.position.x = 40
      directLight.position.y = 40
      directLight.position.z = -40
      //directLightHelper = new THREE.DirectionalLightHelper( directLight, 5 );
      this.d3.scene.add( directLight );

      directLight = new THREE.DirectionalLight( 0xFFFFFF, intensivity )
      directLight.position.x = -40
      directLight.position.y = 40
      directLight.position.z = -40
      //directLightHelper = new THREE.DirectionalLightHelper( directLight, 5 );
      this.d3.scene.add( directLight );

      directLight = new THREE.DirectionalLight( 0xFFFFFF, intensivity )
      directLight.position.x = 40
      directLight.position.y = 40
      directLight.position.z = 40
      //directLightHelper = new THREE.DirectionalLightHelper( directLight, 5 );
      this.d3.scene.add( directLight );


      const envMap = pmremGenerator.fromEquirectangular(this.d3.exr).texture
      //this.d3.scene.background = envMap
      this.d3.scene.environment = envMap

      this.d3.renderer.toneMappingExposure = THREE.ACESFilmicToneMapping
      this.d3.renderer.toneMappingExposure = 2.2
      this.d3.renderer.outputEncoding = THREE.sRGBEncoding
      this.d3.camera.updateProjectionMatrix()

      this.orbitControl.minDistance = 2
      this.orbitControl.maxDistance = 15
      this.orbitControl.target.set(0, 2.2, -0.2)
      this.orbitControl.dampingFactor = 0.05
      this.orbitControl.enableDamping = true
      this.orbitControl.minPolarAngle = 0.4
      this.orbitControl.maxPolarAngle = Math.PI / 2 + 0.2
      //this.orbitControl.enablePan = false
      this.orbitControl.update()

      this.d3.camera.position.x = CameraStartPosition.x
      this.d3.camera.position.y = CameraStartPosition.y
      this.d3.camera.position.z = CameraStartPosition.z
      this.d3.camera.rotation.x = CameraStartPosition.rx
      this.d3.camera.rotation.y = CameraStartPosition.ry
      this.d3.camera.rotation.z = CameraStartPosition.rz
      this.d3.internaldisplay.scale.x =
      this.d3.internaldisplay.scale.y =
      this.d3.internaldisplay.scale.z = 0

      this.d3.old_controller.scale.x =
      this.d3.old_controller.scale.y =
      this.d3.old_controller.scale.z = 0

      this.d3.new_controller.scale.x =
      this.d3.new_controller.scale.y =
      this.d3.new_controller.scale.z = 1

      this.d3.pillar.rotation.x = CapPillarPosition.start.rx
      this.d3.pistons.position.z = CapPillarPistonsPosition.start.z
    },
    AnimDoor(isNeedOpened) {
      let door_rz = isNeedOpened ? DoorPosition.stop.rz : DoorPosition.start.rz
      //let handle_rz1 = isNeedOpened ? DoorHandlePosition.position_0.rz : DoorHandlePosition.start.rz
      let handle_rx1 = isNeedOpened ? DoorHandlePosition.position_0.rx : 0
      let handle_ry1 = isNeedOpened ? DoorHandlePosition.position_0.ry : 0
      let handle_rx2 = isNeedOpened ? DoorHandlePosition.stop.rx : DoorHandlePosition.start.rx
      let handle_ry2 = isNeedOpened ? DoorHandlePosition.stop.ry : DoorHandlePosition.start.ry
      let anim = gsap.timeline()
      let handleAnim = gsap.timeline()
      let animHandleUpDown = gsap.to(this.d3.handle.rotation, {
        x: handle_rx1,
        y: handle_ry1,
        duration: 0.2,
        ease: isNeedOpened ? "power4.out" : "expo.inOut"
      })
      let animHandleLeftRight = gsap.to(this.d3.handle.rotation, {
        x: handle_rx2,
        y: handle_ry2,
        duration:  0.7,
        delay : isNeedOpened ? 0.2 : 0,
        ease: isNeedOpened ? "power3.inOut" : "power3.out"
      })
      let doorAnim = gsap.to(this.d3.door.rotation, {
        z: door_rz,
        duration: (isNeedOpened) ? 2.0 : 1.5,
        ease: (isNeedOpened) ? "back.out(1.0)" : "power3.inOut"
      })

      if (isNeedOpened) {
        handleAnim.add(animHandleUpDown)
        handleAnim.add(animHandleLeftRight, "-=0.1")
        handleAnim.add(doorAnim)
      } else {
        handleAnim.add(doorAnim)
        handleAnim.add(animHandleLeftRight)
        handleAnim.add(animHandleUpDown, "-=0.1")
      }
      anim.add(handleAnim)
      return anim
    },
    AnimHood(isNeedOpened) {
      let duration = 2.0
      let hood_rx = CapPosition.stop.rx
      let pillar_rx = CapPillarPosition.stop.rx
      let pillar_z = CapPillarPistonsPosition.stop.z

      if (!isNeedOpened) {
        hood_rx = CapPosition.start.rx
        pillar_rx = CapPillarPosition.start.rx
        pillar_z = CapPillarPistonsPosition.start.z
      }

      let hoodAnim = gsap.timeline()
      hoodAnim.add(gsap.to(this.d3.hood.rotation, {
        x: hood_rx,
        duration: duration,
        ease: "power4.out"
      }))
      hoodAnim.add(gsap.to(this.d3.pillar.rotation, {
        x: pillar_rx,
        duration: isNeedOpened ? duration - 0.15 : duration + 0.2,//(isNeedOpened) ? duration - 0.1 : duration + 0.16,
        ease: "power4.out"
      }), "-=" + (duration).toString())
      hoodAnim.add(gsap.to(this.d3.pistons.position, {
        z: pillar_z,
        duration: isNeedOpened ? duration - 0.55 : duration - 0.55, //(isNeedOpened) ? duration - 0.3 : duration + 0.46,
        ease: "power4.out"
      }), "-=" + (duration).toString())
      return hoodAnim
    },
    AnimBoxDoor(isNeedOpened) {
      let duration = 1.7
      let door_rz = isNeedOpened ? BoxDoorPosition.stop.rz : BoxDoorPosition.start.rz
      let boxDoorAnim = gsap.to(this.d3.boxdoor.rotation, {
        z: door_rz,
        duration: duration,//(isNeedOpened) ? duration - 0.1 : duration + 0.16,
        ease: (isNeedOpened) ? "back.out(0.85)" : "power3.inOut"
      })
      return boxDoorAnim
    },
    AnimGlass(isNeedRemoved) {
      let duration = 1.5
      let opacity = isNeedRemoved ? 0 : 1
      let glassAnim = gsap.to(this.d3.glass.material, {
        opacity: opacity,
        duration: duration,//(isNeedOpened) ? duration - 0.1 : duration + 0.16,
        ease: "power3.inOut"
      })
      return glassAnim
    },
    AnimFansBlock(isNeedRemoved) {
      let duration = 1.5
      let opacity = isNeedRemoved ? 0 : 1
      let fansblockAnim = gsap.to(this.d3.fansblock.material, {
        opacity: opacity,
        duration: duration,//(isNeedOpened) ? duration - 0.1 : duration + 0.16,
        ease: "power3.inOut"
      })
      return fansblockAnim
    },
    AnimPowerBlock(isNeedRemoved) {
      let duration = 1.5
      let opacity = isNeedRemoved ? 0 : 1
      let powerBlockAnim = gsap.to(this.d3.powerblock.material, {
        opacity: opacity,
        duration: duration,//(isNeedOpened) ? duration - 0.1 : duration + 0.16,
        ease: "power3.inOut"
      })
      return powerBlockAnim
    },
    switchSecureScreen(isNeedShow) {
      let secureContent = document.getElementById('secureContent')
      let secureWord = document.getElementById('secureWord')
      secureWord.value = ''
      var opacity = 0
      if (isNeedShow) {
        opacity = 1
        secureContent.style.display = 'flex'
      } else {
        opacity = 0
      }
      gsap.to('#secureContent', {
        opacity: opacity,
        duration: 0.4,
        ease: "power2.out",
        onComplete: () => {
          if (!isNeedShow) {
            secureContent.style.display = 'none'
          } else {
            var secureWord = document.getElementById('secureWord')
            secureWord.focus()
          }
        }
      })
    },
    onSecureClick() {
      var isSecured = this.$store.getters['general/isSecureMode']()
      if (isSecured) {
        this.switchSecureScreen(true)
      } else {
        this.$store.dispatch("general/changeSecureModeState", true)
      }
    },
    onCloseSecureClick() {
      this.switchSecureScreen(false)
    },
    oncheckSecureKeyClick(e) {
      console.log(e.keyCode)
      if (e.keyCode && (e.keyCode === 27)) { this.switchSecureScreen(false); return }
      if (e.keyCode && (e.keyCode != 13)) { return }
      var isSecured = this.$store.getters['general/isSecureMode']()
      var secureWord = document.getElementById('secureWord')
      var t = this.$store.getters['general/securekey']()
      var c = sha256(secureWord.value)
      this.$store.dispatch("general/changeSecureModeState", (t==c) ? !isSecured : true)
      this.switchSecureScreen(false)
    },
    onMoreInfoClick() {
      let moreDetails = document.getElementById('moreDetails')
      let moreDetailsContent = document.getElementById('DetailsContent')
      let isopened = moreDetails.classList.contains('on')
      let opacity = 0
      if (isopened) {
        opacity = 0
        moreDetails.classList.remove('on')
      } else {
        moreDetailsContent.style.display = 'block'
        opacity = 1
        moreDetails.classList.add('on')
      }
      gsap.to('#DetailsContent', {
        opacity: opacity,
        duration: 0.4,
        ease: "power2.out",
        onComplete: () => {
          if (isopened) moreDetailsContent.style.display = 'none'
        }
      })
    },
    onModeComboClick() {
      let comboList = document.getElementsByClassName('modelist')[0]
      let isopened = comboList.classList.contains('opened')
      if (isopened) {
        comboList.style.display = 'none'
        comboList.style.opacity = 0
        comboList.classList.remove('opened')
      } else {
        comboList.style.display = 'flex'
        comboList.style.opacity = 1
        comboList.classList.add('opened')
      }
    },
    onModeComboItemClick(event) {
      let item = event.currentTarget
      let modeId = parseInt(item.dataset.modeid)
      let comboList = document.getElementsByClassName('modelist')[0]
      this.$store.dispatch("general/changeModeState", modeId)
      comboList.style.display = 'none'
      comboList.style.opacity = 0
      comboList.classList.remove('opened')
    },
    onFirstBtnClick() {
      //this.orbitControl.enabled = false
      let btn = document.getElementsByClassName('btn first')[0]
      this.$store.dispatch("general/changeDoorState", !btn.classList.contains('on'))
    },
    onSecondBtnClick() {
      //this.orbitControl.enabled = false
      let btn = document.getElementsByClassName('btn second')[0]
      this.$store.dispatch("general/changeHoodState", !btn.classList.contains('on'))
    },
    onThirdBtnClick() {
      //this.orbitControl.enabled = false
      let btn = document.getElementsByClassName('btn third')[0]
      this.$store.dispatch("general/changeBoxDoorState", !btn.classList.contains('on'))
    },
    onFourthBtnClick() {
      //this.orbitControl.enabled = false
      let btn = document.getElementsByClassName('btn fourth')[0]
      this.$store.dispatch("general/changeGlassState", !btn.classList.contains('on'))
    },
    onFifthBtnClick() {
      var isSecured = this.$store.getters['general/isSecureMode']()
      if (isSecured) {
        this.switchSecureScreen(true)
        return
      }
      let btn = document.getElementsByClassName('btn fifth')[0]
      this.$store.dispatch("general/changeFansBlockState", !btn.classList.contains('on'))
    },
    onSixthBtnClick() {
      var isSecured = this.$store.getters['general/isSecureMode']()
      if (isSecured) {
        this.switchSecureScreen(true)
        return
      }
      let btn = document.getElementsByClassName('btn sixth')[0]
      this.$store.dispatch("general/changePowerBlockState", !btn.classList.contains('on'))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#titleBlock {
  position: absolute;
  width: 95%;
  left: 90px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  z-index: 6;
  opacity: 0;
}

#checkSecureKey {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0,68,124, 1);
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 6px;
}

#checkSecureKey:hover {
  background-color: rgba(0,68,124, 0.9);
}

#closeSecure{
  width: 46px;
  height: 46px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../assets/icons/buttons/close.svg');
  align-self: flex-end;
  margin-right: 20px;
  margin-top: 20px;
}

#secureWord {
  height: 40px;
  line-height: 40px;
  color: rgba(0,68,124, 1);
  padding: 2px 10px;
}

.pageTitle {
  font-family: ITCAvantGarde-Bold;
  color: #444444;
  font-size: 20px;
  user-select: none;
}

.pageTitle .subtitle {
  font-family: ITCAvantGarde-Bk;
  color: #777777;
  font-size: 16px;
  user-select: none;
}

.pageSubtitle {
  color: #75797B;
  font-size: 18px;
  width: 100%;
  word-wrap: break-word;
  text-align: left;
  margin-top: 0px;
  user-select: none;
}

#d3Canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 5;
}

#DetailsContent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,1);
  background-size: auto 70%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/content/SmrtKrft_info_sheet_correct.svg');
  opacity: 0;
  display: none;
  z-index: 6;
}

#secureContent{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: none;
  z-index: 15;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#productName {
  position: absolute;
  top: 20px;
  right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  opacity: 0;
  z-index: 2;
}

#kraftLogo {
  width: 195px;
  height: 100px;
  background-color: transparent;
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url('../assets/logo/kraftpowerconSmartkraft.svg');
}

#smartLogo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 200px;
  height: 20px;
  background-color: transparent;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/logo/smartkraft_logo.svg');
  opacity: 0;
  z-index: 2;
}

#ControlsCanvas {
  position: absolute;
  right: 90px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
  opacity: 0;
}

#leftControls {
  position: absolute;
  left: 90px;
  top: 20px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  opacity: 0;
}

#leftBottomControls {
  position: absolute;
  left: 90px;
  bottom: 20px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  opacity: 0;
}

#modeSwitcher {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  z-index: 10;
}

.combo {
  width: 320px;
  height: 46px;
  text-align: left;
  border: 1px solid #C4C4C4;
  background-color: rgba(255,255,255,0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.combo .title {
  font-family: ITCAvantGarde-Bold;
  line-height: 46px;
  font-size: 21px;
  color: #75797B;
  margin: 0 20px;
  user-select: none;
}

.combo .arrow {
  width: 46px;
  height: 46px;
  border-left: 1px solid #C4C4C4;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../assets/icons/buttons/arrow.svg');
}

#modeSwitcher .modelist {
  width: 320px;
  margin-top: 1px;
  background-color: rgba(255,255,255,0.8);
  border: 1px solid #C4C4C4;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  opacity: 0;
}

#modeSwitcher .modelist .item {
  width: 100%;
  line-height: 46px;
  font-size: 21px;
  color: #75797B;
  text-align: left;
  cursor: pointer;
  background-color: rgba(255,255,255,0.8);
  transition: all 0.4s;
  user-select: none;
}

#modeSwitcher .modelist .item span {
  font-family: ITCAvantGarde-Bold;
  margin-left: 20px;
  line-height: 46px;
  font-size: 21px;
  color: #75797B;
  text-align: left;
  cursor: pointer;
  user-select: none;
}

#modeSwitcher .modelist .item span .subtitle {
  font-family: ITCAvantGarde-Bk;
  color: #333333;
  font-size: 16px;
  user-select: none;
}

#modeSwitcher .modelist .item:hover {
  background-color: rgba(240,240,240,0.8);
}

#moreDetails {
  width: 42px;
  height: 42px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
  background-size: 42px 42px;
  background-image: url('../assets/icons/buttons/info_def.svg');
}

#moreDetails:hover {
  background-image: url('../assets/icons/buttons/info_hover.svg');
}

#moreDetails.on {
  background-image: url('../assets/icons/buttons/info_hover.svg');
}

#secureWidget{
  width: 42px;
  height: 42px;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 3px;
  background-size: 42px 84px;
  background-position: top center;
  background-image: url('../assets/icons/buttons/secureIcon.svg');
  opacity: 0.3;
  transition: opacity .05s;
  display: none;
}

#secureWidget:hover {
  opacity: 1;
}

#secureWidget.off {
  background-position: bottom center;
  background-image: url('../assets/icons/buttons/secureIcon.svg');
}

#PreparingMsg {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 80px;
  margin-left: -100px;
  margin-top: -80px;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  line-height: 80px;
  color: #707070;
  display: none;
  opacity: 1;
  z-index: 10;
}

.btn {
  width: 30px;
  height: 30px;
  margin: 0px 20px;
  cursor: pointer;
  background-color: gray;
  background-color: transparent;
  background-size: auto 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  opacity: 1;
  display: block;
}

.btn.first {
  width: 80px;
  height: 79px;
  background-size: 65px 79px;
  background-image: url('../assets/icons/buttons/door_closed_def.svg');
}

.btn.first:hover {
  background-image: url('../assets/icons/buttons/door_closed_hover.svg');
}

.btn.first.on {
  margin-top: 2px;
  background-size: 80px 79px;
  background-image: url('../assets/icons/buttons/door_open_def.svg');
}

.btn.second {
  width: 62px;
  height: 89px;
  margin-bottom: 10px;
  background-size: 62px 72px;
  background-image: url('../assets/icons/buttons/cap_closed_def.svg');
}

.btn.second:hover {
  background-image: url('../assets/icons/buttons/cap_closed_hover.svg');
}

.btn.second.on {
  margin-bottom: 10px;
  background-size: 61px 89px;
  background-image: url('../assets/icons/buttons/cap_open_def.svg');
}

.btn.third {
  width: 69px;
  height: 72px;
  background-size: 43px 72px;
  background-image: url('../assets/icons/buttons/box_closed_def.svg');
}

.btn.third:hover {
  background-image: url('../assets/icons/buttons/box_closed_hover.svg');
}

.btn.third.on {
  background-size: 69px 72px;
  background-image: url('../assets/icons/buttons/box_open_def.svg');
}

.btn.fourth {
  width: 60px;
  height: 87px;
  background-size: 60px 87px;
  background-image: url('../assets/icons/buttons/safety_glass_def.svg');
}

.btn.fourth:hover {
  background-image: url('../assets/icons/buttons/safety_glass_hover.svg');
}

.btn.fourth.on {
  /*background-size: 60px 87px;
  /background-image: url('../assets/icons/buttons/safety_glass_half.svg');*/
  opacity: 0.45;
}

.btn.fifth {
  width: 52px;
  height: 85px;
  background-size: 52px 85px;
  background-image: url('../assets/icons/buttons/2_fans_def.svg');
}

.btn.fifth:hover {
  background-image: url('../assets/icons/buttons/2_fans_hover.svg');
}

.btn.fifth.on {
  /*background-size: 52px 85px;
  background-image: url('../assets/icons/buttons/2_fans_half.svg');*/
  opacity: 0.45;
}

.btn.sixth {
  width: 86px;
  height: 89px;
  background-size: 86px 89px;
  background-image: url('../assets/icons/buttons/capacitors_frame_def.svg');
}

.btn.sixth:hover {
  background-image: url('../assets/icons/buttons/capacitors_frame_hover.svg');
}

.btn.sixth.on {
  /*background-size: 86px 89px;
  background-image: url('../assets/icons/buttons/capacitors_frame_half.svg');
  */
  opacity: 0.45;
}
</style>
